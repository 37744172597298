<template>
  <div class="box__body animated fadeIn">
    <div class="box__body-header">
      <h4 class="box__title box__title-qr">
        <div class="mt-4 text-center">
          <span class="text-body text-16 text-bold-500">Scan with Your Privy App</span>
        </div>
        <template v-if="isExist">
          <span>
            Scan with Privy app connected to the following PrivyID.
          </span>
        </template>
        <template v-else>
          <span v-if="failed" class="text-error">
            Sorry, we're unable to process your request. Please try again or use another method
          </span>
          <span v-else>
            Input your PrivyID to generate the QR code.
          </span>
        </template>

      </h4>

      <div class="box__body">
        <template v-if="!isExist">
          <b-form method="post" @submit="submit">
            <div class="form-group margin-bottom-0 box__body-formqr">
                                <span
                                    class="badge badge-primary badge-alt animated fadeInUp"
                                    variant="default"
                                    v-if="status">Please type your PrivyID (e.g. <em>EX2002</em>)</span>

              <b-form-input
                  class="form-control mt-3"
                  :class="{'form-control--error animated shake': errors }"
                  placeholder="PrivyID"
                  name="user[privyId]"
                  v-model="form.privyId"
                  autocomplete="off"
                  @focus="status = !status"
                  @blur="status = !status"
                  required />

              <p v-if="errors" class="input-error">{{ errors }}</p>
            </div>

            <div class="form-group margin-bottom-0 form-submitqr">
              <b-button id="tag-lg001" variant="danger btn-alt__md" type="submit" :disabled="loading">
                <template v-if="loading">
                  <b-spinner small type="grow"></b-spinner>
                  Loading...
                </template>
                <template v-else>
                  CONTINUE
                </template>
              </b-button>
            </div>

          </b-form>
        </template>
        <template v-else>
          <div class="user animated fadeInUp">
            <div class="d-flex align-items-center">
              <div class="user__image">
                <image-avatar :src="user.avatar" alt="Photo profile" :name="user.name" />
              </div>
              <div class="user__id d-flex align-items-center text-uppercase">
                <span class="privyid privyid-alt">{{ user.privyId }}</span>
              </div>
            </div>
            <a @click="removeSession" class="user__action pointer">
              <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                <path fill="#E42E2C" fill-rule="nonzero" d="M14.232 1.463a8.821 8.821 0 0 1 3.278 3.27 8.841 8.841 0 0 1 1.204 4.517 8.841 8.841 0 0 1-1.204 4.518 8.888 8.888 0 0 1-3.278 3.278 8.841 8.841 0 0 1-4.518 1.204 8.841 8.841 0 0 1-4.517-1.204 8.888 8.888 0 0 1-3.279-3.278A8.841 8.841 0 0 1 .714 9.25c0-1.629.402-3.135 1.204-4.518a8.888 8.888 0 0 1 3.279-3.278A8.841 8.841 0 0 1 9.714.25c1.63 0 3.135.404 4.518 1.213zm-.914 10.512L10.61 9.25l2.707-2.725a.615.615 0 0 0 .009-.887.61.61 0 0 0-.449-.185.657.657 0 0 0-.457.176L9.714 8.354 7.007 5.629a.657.657 0 0 0-.457-.176.61.61 0 0 0-.448.185.61.61 0 0 0-.185.448.57.57 0 0 0 .194.44L8.818 9.25 6.11 11.975a.57.57 0 0 0-.194.44.61.61 0 0 0 .185.447.61.61 0 0 0 .448.185.657.657 0 0 0 .457-.176l2.707-2.725 2.707 2.725a.657.657 0 0 0 .457.176.61.61 0 0 0 .449-.185.61.61 0 0 0 .184-.448.57.57 0 0 0-.193-.44z"/>
              </svg>
            </a>
          </div>

          <div class="box__qr-image text-center">
            <img :src="dataQr.qr_code" alt="QR Code" width="231px" height="231px" />
            <div v-if="inActive || failed" class="box__qr-image__reload d-flex align-items-center justify-content-center" @click="reloadQr">
              <div class="box__qr-reload">
                <img :src="assets.reloadIcon" alt="Reload" />
                <p v-if="failed">Try Again</p>
                <p v-else>Reload</p>
              </div>
            </div>
            <div v-if="loadingSubmit" class="box__qr-image__reload d-flex align-items-center justify-content-center" @click="reloadQr">
              <div class="box__qr-loader">
                <div class="qr-loader"></div>
                <p>Please Wait</p>
              </div>
            </div>
            <div v-if="loading" class="box__qr-image__reload d-flex align-items-center justify-content-center">
              <b-spinner variant="danger" type="grow"></b-spinner>
            </div>
          </div>
        </template>
      </div>

      <div v-if="isExist">
        <span>
          Open the Privy app on your phone, and tap <b>Scan QR code</b> to sign in.
        </span>
      </div>

      <div v-if="isDownloadapp">
        <div class="mt-4 text-center">
          <span class="text-body text-14 text-bold-500">Don’t have PrivyID App? Download Now</span>
        </div>
        <div class="mt-2 mb-4 d-flex align-items-center justify-content-between">
          <a href="https://play.google.com/store/apps/details?id=com.privygate.privyid" id="tag-lg003" target="_blank">
            <img :src="assets.googlePlay" alt="Google Play">
          </a>
          <a href="https://apps.apple.com/au/app/privyid-digital-signature/id1218828609" id="tag-lg004" target="_blank">
            <img :src="assets.appStore" alt="App Store">
          </a>
        </div>
      </div>
      <div v-if="this.$props.allow_login_by.includes('password') || this.$props.allow_login_by.length == 0">
        <div class="mt-4 box__text-back" @click="changeMethodLogin">
          <p><img :src="assets.chevronLeft" alt="QR Code" width="20" height="20" /> Log in with PrivyID and Password</p>
        </div>
      </div>
    </div>
    <b-form method="post" :action="`/login${query_params}`" ref="formQr">
      <input type="hidden" name="user[code]" v-model="form.code" >
      <input type="hidden" name="authenticity_token" v-model="form.authenticity_token" >
      <input type="hidden" :name="form.captcha.name" v-model="form.captcha.token" :data-sitekey="form.captcha.sitekey" class="g-recaptcha g-recaptcha-response ">
    </b-form>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import useFirebase from '../plugins/firebase';
import qrDefault from 'images/qr-default.svg';
import chevronLeft from 'images/chevron--left.svg';
import googlePlay from 'images/google-play.svg';
import appStore from 'images/app-store.svg';
import reloadIcon from 'images/reload-icon.svg';
import securityIcon from 'images/security-icon.svg';
import imageAvatar from './avatar'
import '../plugins/gtag'

export default {
  components:{
    imageAvatar
  },
  props: {
    allow_login_by: {
      type: Array,
      default: []
    },
    query_params: {
      type: String,
      default: ''
    },
    api_key: {
      type: String,
      default: '',
    },
    auth_domain: {
      type: String,
      default: '',
    },
    database_url: {
      type: String,
      default: '',
    },
    storage_bucket: {
      type: String,
      default: '',
    },
  },
  computed: {
    appRedirect: function () {
      let key = ['app']

      let site_url = new URL(window.location.href)
      let redirect_uri = site_url.searchParams.get('redirect_uri')
      let is_app = _.some(key, (el) => _.includes(redirect_uri, el))

      return is_app
    },
  },

  data() {
    return {
      errors:'',
      failed: false,
      loading: false,
      loadingSubmit: false,
      inActive: false,
      dataQr: {
        qr_token: '',
        qr_code: qrDefault,
        expired_at: ''
      },
      form: {
        code: '',
        authenticity_token: '',
        captcha: {
          name: '',
          sitekey: '',
          token: '',
        },
      },
      assets: {
        qrDefault: qrDefault,
        chevronLeft: chevronLeft,
        googlePlay: googlePlay,
        appStore: appStore,
        reloadIcon: reloadIcon,
        securityIcon: securityIcon
      },
      isDownloadapp: true,
      expiredTime: 96000,
      isExist: false,
      status: false,
      user: {
        avatar: '',
        name: '',
      },
      clientId: ''
    };
  },
  mounted() {
    this.init()
    this.listenFirebase()
  },
  methods: {
    init() {
      this.load()
      this.$nextTick(() => {
        this.expiredToken(this.expiredTime)
        this.changeToken()
      })
    },
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()
      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login')
      this.form.captcha.token = token
    },
    load() {
      const errors = JSON.parse(document.getElementById('x-flash-errors').value)
      this.errors = _.get(errors, '0.1')

      this.form.authenticity_token = document.getElementById('x-authenticity-token').value
      const captcha = document.getElementById("g-recaptcha-response-data-login")
      if(captcha){
        this.form.captcha.sitekey = captcha.getAttribute('data-sitekey')
        this.form.captcha.name = captcha.name
      }

      const url = new URL(window.location.href);
      this.isDownloadapp = (url.searchParams.get('download_app') == null ? true : url.searchParams.get('download_app') === 'true');
      this.clientId = url.searchParams.get('client_id');

      if (this.errors) {
        this.failed = true
        this.dataQr.qr_code = this.assets.qrDefault
      } else {
        const privyId = this.getWithExpiry("privyId") //localStorage.getItem("privyId")
        if (privyId){
          this.checkPrivyIdQR(privyId)
          // this.generateQr()
        }
      }
    },
    generateQr(){
      this.loading = true
      return axios.post('/qr-code', { privyId: this.form.privyId, authenticity_token: this.form.authenticity_token, clientId: this.clientId } )
          .then((response) => {
            this.dataQr = response.data.data
            this.listenFirebase(this.dataQr.qr_token)
          })
          .catch((error) => {
            console.log(error)
            this.inActive = true
            this.dataQr.qr_code = this.assets.qrDefault
          })
          .then(() => {
            this.loading = false
          })
    },
    listenFirebase(qr_token) {
      let token = qr_token

      useFirebase(this.$props).ref(`/auth/qr_codes/${token}`).on('value', (data) => {
        let status = data.val() ? data.val().status : ''

        if (status === 'verified') {
          this.loadingSubmit = true
          this.form.code = data.val().code

          this.$nextTick(() => {
            if (!this.form.captcha.token) {
              this.recaptcha()
                  .then(() => {
                    this.$refs.formQr.submit()
                    if (this.appRedirect)
                      this.$gtag.event('conversion', {'send_to': 'AW-457926106/bOzhCP2M3N0CENrLrdoB'})
                  })
            } else {
              this.$refs.formQr.submit()
              if (this.appRedirect)
                this.$gtag.event('conversion', {'send_to': 'AW-457926106/bOzhCP2M3N0CENrLrdoB'})
            }
          })
        }
      })
    },
    changeToken() {
      const interval = setInterval(() => {
        if(!this.inActive) {
          let d = new Date(this.dataQr.expired_at)
          d.setSeconds(d.getSeconds() - 4)
          if (new Date() > d) {
            if (!this.loading)
              this.generateQr()
          }
        } else {
          clearInterval(interval)
        }
      }, 1000)

    },
    expiredToken(expiredTime) {
      const interval = setInterval(() => {
        if (!this.inActive)
          this.inActive = true
        else
          clearInterval(interval)
      }, expiredTime)
    },
    changeMethodLogin() {
      this.$emit('change', false)
    },
    reloadQr() {
      this.inActive = false
      this.clearErrors()
      this.$nextTick(() => {
        this.init()
      })
    },
    clearErrors(){
      this.errors  = ''
      this.failed  = false
      document.getElementById('x-flash-errors').value = '[]'
    },
    checkPrivyIdQR(privyId){
      this.loading = true
      this.inActive = false
      return axios.post('/user/check', { privyId: privyId, authenticity_token: this.form.authenticity_token } )
          .then((response) => {
            this.user = response.data.data
            this.isExist = this.user.exist
            this.form.privyId = privyId

            if(this.isExist == false){
              this.errors = response.data.message
            } else {
              this.setWithExpiry("privyId", this.form.privyId, 900000)
            }
          })
          .catch((error) => {
            if (error.response && error.response.status == 422){
              let getDataError = _.head(error.response.data.errors)
              if (getDataError) this.errors = _.head(getDataError.messages)
            }

            if (error.response && error.response.status == 429){
              this.setWithExpiry("privyId", this.form.privyId, 5000)
              // localStorage.setItem("privyId", this.form.privyId)
              window.location.href = '/login'

            }

            this.isExist = false
            console.log(error)
          })
          .then(() => {
            this.loading = false
            this.generateQr()
            // this.$nextTick(() => {
            //   this.expiredToken(this.expiredTime)
            //   this.changeToken()
            // })
          })
    },
    submit(event){
      if (!this.isExist) {
        event.preventDefault()
        this.clearErrors()
        return this.checkPrivyIdQR(this.form.privyId)
      }
      if (!this.form.captcha.token) {
        event.preventDefault()
        this.loading = true
        this.recaptcha()
            .then(() => {
              event.target.submit()
              if (this.appRedirect)
                this.$gtag.event('conversion', {'send_to': 'AW-457926106/bOzhCP2M3N0CENrLrdoB'})
            })
      }
      this.loading = false
      return event
    },
    setWithExpiry(key, value, ttl) {
      const now = new Date()

      // `item` is an object which contains the original value
      // as well as the time when it's supposed to expire
      const item = {
        value: value,
        expiry: now.getTime() + ttl,
      }
      localStorage.setItem(key, JSON.stringify(item))
    },
    getWithExpiry(key) {
      const itemStr = localStorage.getItem(key)
      // if the item doesn't exist, return null
      if (!itemStr) {
        return null
      }
      const item = JSON.parse(itemStr)
      const now = new Date()
      // compare the expiry time of the item with the current time
      if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
      }
      return item.value
    },
    removeSession(){
      localStorage.removeItem("privyId")
      this.form.privyId = ''
      this.isExist = false
      this.clearErrors()
    },
  }
};
</script>
