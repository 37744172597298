<template>
  <div class="box__body animated fadeIn">
    <div class="box__body-header">
      <h4 class="box__title box__title-qr text-body">
        <div class="mt-4 text-center">
          <span class="text-body text-16 text-bold-500">Scan with Your Privy App</span>
        </div>
        <template v-if="isExist">
          <span>
            Scan with Privy app connected to the following PrivyID.
          </span>
        </template>
        <template v-else>
          <span v-if="failed" class="text-error">
            Sorry, we're unable to process your request. Please try again or use another method
          </span>
          <span class="text-body text-12" v-else>
              Input your PrivyID to generate the QR code.
          </span>
        </template>
      </h4>

      <div class="box__body">
        <template v-if="!isExist">
          <b-form method="post" @submit="submit">
            <div class="form-group margin-bottom-0 box__body-formqr">
                                <span
                                    class="badge badge-primary badge-alt animated fadeInUp"
                                    variant="default"
                                    v-if="status">Please type your PrivyID (e.g. <em>EX2002</em>)</span>

              <b-form-input
                  class="form-control mt-3"
                  :class="{'form-control--error animated shake': errors }"
                  placeholder="PrivyID"
                  name="user[privyId]"
                  v-model="form.privyId"
                  autocomplete="off"
                  @focus="status = !status"
                  @blur="status = !status"
                  required />

              <p v-if="errors" class="input-error">{{ errors }}</p>
            </div>

            <div class="form-group margin-bottom-0 form-submitqr">
              <b-button id="tag-lg001" variant="danger btn-alt__md" type="submit" :disabled="loading">
                <template v-if="loading">
                  <b-spinner small type="grow"></b-spinner>
                  Loading...
                </template>
                <template v-else>
                  CONTINUE
                </template>
              </b-button>
            </div>

          </b-form>
        </template>
        <template v-else>
          <div class="user animated fadeInUp">
            <div class="d-flex align-items-center">
              <div class="user__image">
                <image-avatar :src="user.avatar" alt="Photo profile" :name="user.name" />
              </div>
              <div class="user__id d-flex align-items-center text-uppercase">
                <span class="privyid privyid-alt">{{ user.privyId }}</span>
              </div>
            </div>
            <a @click="removeSession" class="user__action pointer">
              <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                <path fill="#E42E2C" fill-rule="nonzero" d="M14.232 1.463a8.821 8.821 0 0 1 3.278 3.27 8.841 8.841 0 0 1 1.204 4.517 8.841 8.841 0 0 1-1.204 4.518 8.888 8.888 0 0 1-3.278 3.278 8.841 8.841 0 0 1-4.518 1.204 8.841 8.841 0 0 1-4.517-1.204 8.888 8.888 0 0 1-3.279-3.278A8.841 8.841 0 0 1 .714 9.25c0-1.629.402-3.135 1.204-4.518a8.888 8.888 0 0 1 3.279-3.278A8.841 8.841 0 0 1 9.714.25c1.63 0 3.135.404 4.518 1.213zm-.914 10.512L10.61 9.25l2.707-2.725a.615.615 0 0 0 .009-.887.61.61 0 0 0-.449-.185.657.657 0 0 0-.457.176L9.714 8.354 7.007 5.629a.657.657 0 0 0-.457-.176.61.61 0 0 0-.448.185.61.61 0 0 0-.185.448.57.57 0 0 0 .194.44L8.818 9.25 6.11 11.975a.57.57 0 0 0-.194.44.61.61 0 0 0 .185.447.61.61 0 0 0 .448.185.657.657 0 0 0 .457-.176l2.707-2.725 2.707 2.725a.657.657 0 0 0 .457.176.61.61 0 0 0 .449-.185.61.61 0 0 0 .184-.448.57.57 0 0 0-.193-.44z"/>
              </svg>
            </a>
          </div>

          <div class="box__qr-image text-center">
            <img :src="dataQr.qr_code" alt="QR Code" width="231px" height="231px" />
            <div v-if="inActive || failed" class="box__qr-image__reload d-flex align-items-center justify-content-center" @click="reloadQr">
              <div class="box__qr-reload">
                <img :src="assets.reloadIcon" alt="Reload" />
                <p v-if="failed">Try Again</p>
                <p v-else>Reload</p>
              </div>
            </div>
            <div v-if="loadingSubmit" class="box__qr-image__reload d-flex align-items-center justify-content-center" @click="reloadQr">
              <div class="box__qr-loader">
                <div class="qr-loader"></div>
                <p>Please Wait</p>
              </div>
            </div>
            <div v-if="loading" class="box__qr-image__reload d-flex align-items-center justify-content-center">
              <b-spinner variant="danger" type="grow"></b-spinner>
            </div>
          </div>
        </template>
      </div>

      <div v-if="isExist">
        <span>
          Open the Privy app on your phone, and tap <b>Scan QR code</b> to sign in.
        </span>
      </div>

      <div v-if="isDownloadapp">
        <div class="mt-4 text-center">
          <span class="text-body text-14 text-bold-500">Don’t have PrivyID App? Download Now</span>
        </div>
        <div class="mt-2 mb-4 d-flex align-items-center gap-3 justify-content-between">
          <div class="col-6">
            <a href="https://play.google.com/store/apps/details?id=com.privygate.privyid" id="tag-lg003" target="_blank">
              <img :src="assets.googlePlay" alt="Google Play">
            </a>
          </div>
          <div class="col-6">
            <a href="https://apps.apple.com/au/app/privyid-digital-signature/id1218828609" id="tag-lg004" target="_blank">
              <img :src="assets.appStore" alt="App Store">
            </a>
          </div>
        </div>
      </div>
      <div class="mt-4 py-3 border-top text-center">
        <a :href="linkRegister" class="btn btn-outline-brand btn-alt text-uppercase mt-1 shadow-none hover:text-white">
          Register
        </a>
        <span class="d-block small mt-2">Click to create an account with Privy</span>
      </div>
      <div v-if="this.$props.allow_login_by.includes('password') || this.$props.allow_login_by.length == 0">
        <div class="login-method">
          <div class="login-method__strip"></div>
          <div class="login-method__text" @click="expandLoginMethod">
            Other Method
            <img :src="dropdownIcon" alt="Method QR Code">
          </div>
          <div class="login-method__strip"></div>
        </div>
        <transition name="slide-fade">
          <div v-show="isExpand" class="login-method-wrapper mt-3" :class="{ 'is-expand': isExpand }">
            <div class="d-flex justify-content-center">
              <div class="box__text-login-method col-5" @click="$emit('change', false)">
                <img :src="assets.securityIcon" alt="Method QR Code">
                <p class="mb-0 mt-2">Password</p>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <b-form method="post" :action="`/login${query_params}`" ref="formQr">
      <input type="hidden" name="user[code]" v-model="form.code" >
      <input type="hidden" name="authenticity_token" v-model="form.authenticity_token" >
      <input type="hidden" :name="form.captcha.name" v-model="form.captcha.token" :data-sitekey="form.captcha.sitekey" class="g-recaptcha g-recaptcha-response ">
    </b-form>
  </div>
</template>
<script>
import LoginQr from './login-qr.vue'
import iconChevronDown from 'images/chevron--down.svg'
import iconChevronUp from 'images/chevron--up.svg'

export default {
  extends: LoginQr,
  props: {
    linkRegister: String
  },
  data() {
    return {
      isExpand: false
    }
  },
  computed: {
    dropdownIcon() {
      return this.isExpand ? iconChevronUp : iconChevronDown
    }
  },
  methods: {
    expandLoginMethod() {
      this.isExpand = !this.isExpand
    }
  }
}
</script>

<style scoped>
.login-method {
  display: grid;
  align-items: center;
  gap: 0.75rem;
  grid-template-columns: 1fr auto 1fr;
}

.login-method__strip {
  height: 1px;
  border-bottom: 1px solid #D6D9DE;
}

/* .login-method-wrapper {
  display: none;
  overflow: hidden;
  -webkit-transition: all 2s ease-out;
  -moz-transition: all 2s ease-out;
  -o-transition: all 2s ease-out;
  transition: all 2s;
}

.login-method-wrapper.is-expand {
  display: block;
} */

.box__text-login-method {
  border: 1px solid #E9EAED;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 12px;
  cursor: pointer;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>