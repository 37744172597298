<template>
    <!-- start:page auth -->
    <div class="pages pages--auth animated fadeIn">
        <div class="box-wrapper">
            <div class="box box-large">
                <!-- start:box header -->
                <div class="box__header mb-4">
                    <img width="140" :src="assets.logo" alt="logo privy">
                </div>
                <!-- end:/box header -->

                <!-- start:box body -->
                <div v-if="!method.qrCode" class="box__body">
                    <b-form method="post" :action="`/login${query_params}`" @submit="submit">
                        <div class="box__body-header">
                            <h3 class="text-body">Log In</h3>

                            <template>
                                <h4 class="box__title">
                                    <p class="box__title-gray">enter your PrivyID</p>
                                </h4>
                            </template>
                        </div>
                        <template v-if="!isExist">
                            <div class="form-group margin-bottom-0">
                                <span
                                    class="badge badge-primary badge-alt animated fadeInUp"
                                    variant="default"
                                    v-if="status">Please type your PrivyID (e.g. <em>EX2002</em>)</span>

                                <b-form-input
                                    class="form-control mt-3"
                                    :class="{'form-control--error animated shake': errors }"
                                    placeholder="PrivyID"
                                    name="user[privyId]"
                                    v-model="form.privyId"
                                    autocomplete="off"
                                    @focus="status = !status"
                                    @blur="status = !status"
                                    required />

                                <p v-if="errors" class="input-error">{{ errors }}</p>
                            </div>
                        </template>
                        <template v-else>
                            <input type="hidden" name="user[privyId]" v-model="form.privyId" >
                            <input type="hidden" name="authenticity_token" v-model="form.authenticity_token" >
                            <input type="hidden" :name="form.captcha.name" v-model="form.captcha.token" :data-sitekey="form.captcha.sitekey" class="g-recaptcha g-recaptcha-response ">

                            <div>
                                <!-- start:user -->
                                <div class="user shadow-none animated fadeInUp">
                                    <div class="d-flex align-items-center">
                                        <div class="user__image">
                                            <image-avatar :src="user.avatar" alt="Photo profile" :name="user.name" />
                                        </div>
                                        <div class="user__id d-flex align-items-center text-uppercase">
                                            <span class="privyid privyid-alt">{{ user.privyId }}</span>
                                        </div>
                                    </div>
                                    <a @click="removeSession" class="user__action pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                            <path fill="#E42E2C" fill-rule="nonzero" d="M14.232 1.463a8.821 8.821 0 0 1 3.278 3.27 8.841 8.841 0 0 1 1.204 4.517 8.841 8.841 0 0 1-1.204 4.518 8.888 8.888 0 0 1-3.278 3.278 8.841 8.841 0 0 1-4.518 1.204 8.841 8.841 0 0 1-4.517-1.204 8.888 8.888 0 0 1-3.279-3.278A8.841 8.841 0 0 1 .714 9.25c0-1.629.402-3.135 1.204-4.518a8.888 8.888 0 0 1 3.279-3.278A8.841 8.841 0 0 1 9.714.25c1.63 0 3.135.404 4.518 1.213zm-.914 10.512L10.61 9.25l2.707-2.725a.615.615 0 0 0 .009-.887.61.61 0 0 0-.449-.185.657.657 0 0 0-.457.176L9.714 8.354 7.007 5.629a.657.657 0 0 0-.457-.176.61.61 0 0 0-.448.185.61.61 0 0 0-.185.448.57.57 0 0 0 .194.44L8.818 9.25 6.11 11.975a.57.57 0 0 0-.194.44.61.61 0 0 0 .185.447.61.61 0 0 0 .448.185.657.657 0 0 0 .457-.176l2.707-2.725 2.707 2.725a.657.657 0 0 0 .457.176.61.61 0 0 0 .449-.185.61.61 0 0 0 .184-.448.57.57 0 0 0-.193-.44z"/>
                                        </svg>
                                    </a>
                                </div>
                                <!-- end:/user -->

                                <div class="form-group margin-bottom-0 position-relative animated" :class="{'fadeInDown': !errors }">
                                    <div class="position-relative">
                                        <b-form-input
                                            :type="showPassword ? 'text' : 'password' "
                                            class="form-control form-control--password"
                                            :class="{'form-control--error animated shake': errors }"
                                            name="user[secret]"
                                            ref="secret"
                                            placeholder="Password"
                                            v-model="form.secret"
                                            required />

                                        <svg data-state="show" class="icon-eye" :class="{ 'inactive': !showPassword }" width="14" height="14" xmlns="http://www.w3.org/2000/svg" @click="showPassword = !showPassword">
                                            <g fill="#CCC" fill-rule="nonzero">
                                                <path d="M.06 6.861c1.452 3.01 3.759 4.532 6.884 4.532 3.124 0 5.43-1.521 6.883-4.532l.06-.124-.066-.121C12.152 3.55 9.851 2 6.944 2 4.035 2 1.734 3.55.066 6.616L0 6.736l.06.125zm6.884-4.323c2.66 0 4.765 1.394 6.337 4.214-1.366 2.745-3.467 4.102-6.337 4.102-2.87 0-4.972-1.357-6.338-4.102 1.572-2.82 3.677-4.214 6.338-4.214z"/>
                                                <path d="M7.033 9.375a2.692 2.692 0 1 0 0-5.384 2.692 2.692 0 0 0 0 5.384zm0-.538a2.154 2.154 0 1 1 0-4.308 2.154 2.154 0 0 1 0 4.308z"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <p v-if="errors" class="input-error">{{ errors }}</p>
                                </div>
                            </div>
                        </template>
                        <div class="form-group margin-top-1">
                            <span class="text-12">
                                {{ forgotPasswordLabel }} <a :href="forgotPasswordLink" id="tag-fg001" class="link font-weight-normal">Click here</a>
                            </span>
                        </div>

                        <!-- <p class="box__text-policies" v-if="!super_app">
                            To continue, We will share your name, email address, phone number, and profile picture with {{ application.name }}. Before using this app, you can review {{ application.name }} <a href="#" target="_blank">privacy policy</a> and <a href="#" target="_blank">terms of service</a>.
                        </p> -->
                        <div class="form-group margin-bottom-0 mt-4">
                            <b-button id="tag-lg001" variant="brand" class="btn-alt" style="width: 206px;" type="submit" :disabled="loading">
                                <template v-if="loading">
                                    <b-spinner small type="grow"></b-spinner>
                                    Loading...
                                </template>
                                <template v-else>
                                    CONTINUE
                                </template>
                            </b-button>
                        </div>
                    </b-form>
                    <div class="border-top mt-4 pt-4" v-if="isRegisterUrl">
                        <a :href="link_register" class="btn btn-outline-brand btn-alt text-uppercase mt-1 shadow-none hover:text-white" style="width: 206px;">Register</a>
                        <span class="d-block text-12 mt-2">Click to create an account with Privy</span>
                    </div>
                  <div v-if="this.application.allow_login_by.includes('qr') || this.application.allow_login_by.length == 0">
                    <div v-if="login_with_qrcode === 'true'" class="mt-5 box__text-qr">
                        <p>Log In with</p>
                        <div id="tag-lg002" class="box__text-qr__content" @click="changeMethodLogin(true)">
                            <img :src="assets.qrIcon" alt="Method QR Code">
                            <span class="ml-2">QR Code</span>
                        </div>
                    </div>
                  </div>
                </div>
                <!-- end:/box body -->
                <template v-if="method.qrCode">
                    <login-qr-v-2
                        :allow_login_by="this.application.allow_login_by"
                        :api_key="api_key"
                        :auth_domain="auth_domain"
                        :database_url="database_url"
                        :storage_bucket="storage_bucket"
                        :link-register="link_register"
                        :query_params="query_params" @change="value => changeMethodLogin(value)" />
                </template>
            </div>
            <div class="powered-by">
                <span>Powered By</span> <img width="53" :src="assets.logo" alt="logo privy">
            </div>
        </div>
    </div>
    <!-- end:/page auth -->

</template>
<script>
import axios from 'axios'
import Login from './login'
import LoginQrV2 from './login-qr_v2'

export default {
    extends: Login,
    components: { LoginQrV2 },
    methods: {
        getClient(){
            return axios.get('/api/v2/client', { params : { client_id: this.application.client_id }} )
                .then((response) => {
                    if(response.data.data)
                        this.application = _.assign(this.application,response.data.data)
                    else
                        this.super_app = true

                    if (this.application.allow_login_by.length == 1 && !this.method.qrCode && this.application.allow_login_by.includes("qr"))
                      this.changeMethodLogin(this.application.allow_login_by.includes("qr"))

                    if (this.application.allow_login_by.length == 1 && this.method.qrCode && this.application.allow_login_by.includes("password"))
                      this.changeMethodLogin(false)
                })
                .catch((error) => {
                    console.log(error);
                })
        },
    }
}
</script>