<template>
  <!-- start:page auth -->
  <div class="pages pages--auth animated fadeIn">
    <div class="box">
      <!-- start:box header -->
      <div class="box__header">
        <span v-if="!super_app">Log in with</span>
        <img width="85" :src="assets.logo" alt="logo privy">
      </div>
      <!-- end:/box header -->

      <!-- start:box body -->
      <div v-if="!method.qrCode" class="box__body">
        <b-form method="post" :action="`/login${query_params}`" @submit="submit">
          <template v-if="!isExist">
            <div class="box__body-header">
              <h3 class="login-text">Hi, Welcome Back 👋</h3>

              <template v-if="!super_app">
                <h4 class="box__title">
                  <p class="box__title-gray">with your Privy Account</p>
                  <span class="box__title-gray--light">to continue to <a :href="application.url" target="_blank">{{
                      application.name
                    }}</a></span>
                </h4>
              </template>

              <h4 class="box__title" v-else>It's good to see you again</h4>
            </div>

            <div class="form-group margin-bottom-0 box__body-form">
                            <span
                                class="badge badge-primary badge-alt animated fadeInUp"
                                variant="default"
                                v-if="status">Please type your PrivyID (e.g. <em>EX2002</em>)</span>

              <b-form-input
                  class="form-control mt-3"
                  :class="{'form-control--error animated shake': errors }"
                  placeholder="PrivyID"
                  name="user[privyId]"
                  v-model="form.privyId"
                  autocomplete="off"
                  @focus="status = !status"
                  @blur="status = !status"
                  required/>

              <p v-if="errors" class="input-error">{{ errors }}</p>
            </div>
            <div class="form-group margin-top-1">
              <a :href="forgotPasswordLink" id="tag-fg001" class="forgot-password">{{ forgotPasswordLabel }}</a>
            </div>
          </template>

          <template v-else>
            <input type="hidden" name="user[privyId]" v-model="form.privyId">
            <input type="hidden" name="authenticity_token" v-model="form.authenticity_token">
            <input type="hidden" :name="form.captcha.name" v-model="form.captcha.token"
                   :data-sitekey="form.captcha.sitekey" class="g-recaptcha g-recaptcha-response ">

            <div class="box__body-header">
              <h3 class="text-danger login-text">Log In</h3>

              <template v-if="!super_app">
                <h4 class="box__title">
                  <p class="box__title-gray">with your Privy Account</p>
                  <span class="box__title-gray--light">to continue to <a :href="application.url" target="_blank">{{
                      application.name
                    }}</a></span>
                </h4>
              </template>

              <template v-else>
                <h4 class="box__title">using your Privy account to <br> continue</h4>
              </template>
            </div>


            <div class="box__body-form">
              <!-- start:user -->
              <div class="user animated fadeInUp">
                <div class="d-flex align-items-center">
                  <div class="user__image">
                    <image-avatar :src="user.avatar" alt="Photo profile" :name="user.name"/>
                  </div>
                  <div class="user__id d-flex align-items-center text-uppercase">
                    <span class="privyid privyid-alt">{{ user.privyId }}</span>
                  </div>
                </div>
                <a @click="removeSession" class="user__action pointer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                    <path fill="#E42E2C" fill-rule="nonzero"
                          d="M14.232 1.463a8.821 8.821 0 0 1 3.278 3.27 8.841 8.841 0 0 1 1.204 4.517 8.841 8.841 0 0 1-1.204 4.518 8.888 8.888 0 0 1-3.278 3.278 8.841 8.841 0 0 1-4.518 1.204 8.841 8.841 0 0 1-4.517-1.204 8.888 8.888 0 0 1-3.279-3.278A8.841 8.841 0 0 1 .714 9.25c0-1.629.402-3.135 1.204-4.518a8.888 8.888 0 0 1 3.279-3.278A8.841 8.841 0 0 1 9.714.25c1.63 0 3.135.404 4.518 1.213zm-.914 10.512L10.61 9.25l2.707-2.725a.615.615 0 0 0 .009-.887.61.61 0 0 0-.449-.185.657.657 0 0 0-.457.176L9.714 8.354 7.007 5.629a.657.657 0 0 0-.457-.176.61.61 0 0 0-.448.185.61.61 0 0 0-.185.448.57.57 0 0 0 .194.44L8.818 9.25 6.11 11.975a.57.57 0 0 0-.194.44.61.61 0 0 0 .185.447.61.61 0 0 0 .448.185.657.657 0 0 0 .457-.176l2.707-2.725 2.707 2.725a.657.657 0 0 0 .457.176.61.61 0 0 0 .449-.185.61.61 0 0 0 .184-.448.57.57 0 0 0-.193-.44z"/>
                  </svg>
                </a>
              </div>
              <!-- end:/user -->

              <div class="form-group margin-bottom-0 position-relative animated" :class="{'fadeInDown': !errors }">
                <div class="position-relative">
                  <b-form-input
                      :type="showPassword ? 'text' : 'password' "
                      class="form-control form-control--password"
                      :class="{'form-control--error animated shake': errors }"
                      name="user[secret]"
                      ref="secret"
                      placeholder="Password"
                      v-model="form.secret"
                      required/>

                  <svg data-state="show" class="icon-eye" :class="{ 'inactive': !showPassword }" width="14" height="14"
                       xmlns="http://www.w3.org/2000/svg" @click="showPassword = !showPassword">
                    <g fill="#CCC" fill-rule="nonzero">
                      <path
                          d="M.06 6.861c1.452 3.01 3.759 4.532 6.884 4.532 3.124 0 5.43-1.521 6.883-4.532l.06-.124-.066-.121C12.152 3.55 9.851 2 6.944 2 4.035 2 1.734 3.55.066 6.616L0 6.736l.06.125zm6.884-4.323c2.66 0 4.765 1.394 6.337 4.214-1.366 2.745-3.467 4.102-6.337 4.102-2.87 0-4.972-1.357-6.338-4.102 1.572-2.82 3.677-4.214 6.338-4.214z"/>
                      <path
                          d="M7.033 9.375a2.692 2.692 0 1 0 0-5.384 2.692 2.692 0 0 0 0 5.384zm0-.538a2.154 2.154 0 1 1 0-4.308 2.154 2.154 0 0 1 0 4.308z"/>
                    </g>
                  </svg>
                </div>
                <p v-if="errors" class="input-error">{{ errors }}</p>
              </div>
            </div>
            <div class="form-group margin-top-1">
              <a :href="forgotPasswordLink" id="tag-fg001" class="forgot-password">{{ forgotPasswordLabel }}</a>
            </div>
          </template>

          <p class="box__text-policies" v-if="!super_app">
            To continue, We will share your name, email address, phone number, and profile picture with
            {{ application.name }}. Before using this app, you can review {{ application.name }} <a href="#"
                                                                                                    target="_blank">privacy
            policy</a> and <a href="#" target="_blank">terms of service</a>.
          </p>
          <div class="form-group margin-bottom-0 form-submit">
            <b-button id="tag-lg001" variant="danger btn-alt__md" type="submit" :disabled="loading">
              <template v-if="loading">
                <b-spinner small type="grow"></b-spinner>
                Loading...
              </template>
              <template v-else>
                CONTINUE
              </template>
            </b-button>
          </div>
        </b-form>
        <div class="border-top register" v-if="isRegisterUrl">
          <a :href="link_register"
             class="btn btn-outline-danger btn-alt__md text-uppercase mt-1 shadow-none text-danger hover:text-white">Register
            Account</a>
          <span class="d-block small mt-2">Create new account by click link above</span>
        </div>
        <div v-if="this.application.allow_login_by.includes('qr') || this.application.allow_login_by.length == 0">
          <div
              v-if="login_with_qrcode === 'true'"
              class="mt-5 box__text-qr">
            <p>Log In with</p>
            <div id="tag-lg002" class="box__text-qr__content" @click="changeMethodLogin(true)">
              <img :src="assets.qrIcon" alt="Method QR Code">
              <span class="ml-2">QR Code</span>
            </div>
          </div>
        </div>
      </div>
      <!-- end:/box body -->
      <template v-if="method.qrCode">
        <login-qr
            :allow_login_by="this.application.allow_login_by"
            :api_key="api_key"
            :auth_domain="auth_domain"
            :database_url="database_url"
            :storage_bucket="storage_bucket"
            :query_params="query_params" @change="value => changeMethodLogin(value)"/>
      </template>
    </div>
  </div>
  <!-- end:/page auth -->

</template>

<script>
    import axios from 'axios'
    import _ from 'lodash'
    import logo from 'images/logo.svg'
    import qrIcon from 'images/qr-icon.svg'
    import defaultAvatar from 'images/images-user.png'
    import imageAvatar from './avatar'
    import loginQr from './login-qr'
    import '../plugins/gtag'

export default {
  components: {
    imageAvatar,
    loginQr
  },
  props: [
    'url_register',
    'login_with_qrcode',
    'api_key',
    'auth_domain',
    'database_url',
    'storage_bucket',
    'url_register_aussie',
    'forgot_password_url',
    'forgot_password_url_aussie',
    'forgot_password_label',
    'forgot_password_label_aussie'
  ],
  computed: {
    link_register: function () {
      const original_url = new URL(window.location.href);
      let url = new URL(this.url_register);
      if (original_url.hostname.includes('.com.au')) {
        url = new URL(this.url_register_aussie);
      }

      const ref_id = original_url.searchParams.get("ref_id");
      if (ref_id) url.searchParams.append('ref_id', ref_id);

      return url.href
    },
    appRedirect: function () {
      let key = ['app']

      let site_url = new URL(window.location.href)
      let redirect_uri = site_url.searchParams.get('redirect_uri')
      let is_app = _.some(key, (el) => _.includes(redirect_uri, el))

      return is_app
    },
    forgotPasswordLink: function () {
      let url = this.forgot_password_url

      const original_url = new URL(window.location.href);
      if (original_url.hostname.includes('.com.au')) {
        url = this.forgot_password_url_aussie
      }

      if (this.application.client_id && this.application.client_id.length > 0) {
        url += "?client_id=" + this.application.client_id
      }
      return url
    },
    forgotPasswordLabel: function () {
      let label = this.forgot_password_label

      const original_url = new URL(window.location.href);
      if (original_url.hostname.includes('.com.au')) {
        label = this.forgot_password_label_aussie
      }

      return label

    },
  },

  data() {
    return {
      loading: false,
      errors: '',
      user: {
        avatar: '',
        name: '',
      },
      status: false,
      application: {
        name: '',
        client_id: '',
        url: '',
        allow_login_by: [],
        allow_tfa_by: [],
      },
      form: {
        authenticity_token: '',
        privyId: '',
        secret: '',
        isLogin: false,
        captcha: {
          name: '',
          sitekey: '',
          token: '',
        },
      },
      isExist: false,
      showPassword: false,
      query_params: '',
      assets: {
        logo: logo,
        defaultAvatar: defaultAvatar,
        qrIcon: qrIcon
      },
      super_app: false,
      imgAvatar: {
        loading: defaultAvatar,
        error: defaultAvatar
      },
      isRegisterUrl: false,
      method: {
        qrCode: false
      }
    }
  },
  mounted() {
    this.getCsrfToken()
    this.load()
  },
  methods: {
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login')
      this.form.captcha.token = token
    },
    load() {
      const url = new URL(window.location.href);

      this.isRegisterUrl = !!(url.searchParams.get('register') == 'true');
      this.application.client_id = url.searchParams.get("client_id");
      this.method.qrCode = url.searchParams.get('method') && url.searchParams.get('method') === 'qr' ? true : false

      if (this.application.client_id)
        this.getClient()
      else
        this.super_app = true
      this.query_params = window.location.search

      const errors = JSON.parse(document.getElementById('x-flash-errors').value)

      this.errors = _.get(errors, '0.1')
      this.form.authenticity_token = document.getElementById('x-authenticity-token').value

      const captcha = document.getElementById("g-recaptcha-response-data-login")
      if (captcha) {
        this.form.captcha.sitekey = captcha.getAttribute('data-sitekey')
        this.form.captcha.name = captcha.name
      }

                const privyId = this.getWithExpiry("privyId") //localStorage.getItem("privyId")
                if (privyId){
                    this.checkPrivyId(privyId)
                }
            },
            getCsrfToken(){
                let token = document.head.querySelector('meta[name="csrf-token"]')
                if (token) {
                    axios.defaults.headers.common['X-CSRF-Token'] = token.content;
                } else {
                    console.error('CSRF Token not found');
                }
            },
            submit(event){
                if (!this.isExist) {
                    event.preventDefault()
                    this.clearErrors()
                    return this.checkPrivyId(this.form.privyId)
                }
                if (!this.form.captcha.token) {
                    event.preventDefault()
                    this.loading = true
                    this.recaptcha()
                        .then(() => {
                            event.target.submit()
                            if (this.appRedirect)
                                this.$gtag.event('conversion', {'send_to': 'AW-457926106/bOzhCP2M3N0CENrLrdoB'})
                    })
                }
                this.loading = true
                return event
            },
            checkPrivyId(privyId){
                this.loading = true
                return axios.post('/user/check', { privyId: privyId, authenticity_token: this.form.authenticity_token } )
                    .then((response) => {
                        this.user = response.data.data
                        this.isExist = this.user.exist
                        this.form.privyId = privyId

            if (this.isExist && !this.method.qrCode) {
              this.$nextTick(() => {
                this.$refs.secret.focus()
              })
            }

            if (this.isExist == false) {
              this.errors = response.data.message
            } else {
              // this.errors = ''
              this.setWithExpiry("privyId", this.form.privyId, 900000)
              // localStorage.setItem("privyId", this.form.privyId);
            }

          })
          .catch((error) => {
            if (error.response && error.response.status == 422) {
              let getDataError = _.head(error.response.data.errors)
              if (getDataError) this.errors = _.head(getDataError.messages)
            }

            if (error.response && error.response.status == 429) {
              this.setWithExpiry("privyId", this.form.privyId, 5000)
              // localStorage.setItem("privyId", this.form.privyId)
              window.location.href = '/login'

            }

            this.isExist = false
            console.log(error)
          })
          .then(() => {
            this.loading = false
          })
    },
    getClient() {
      return axios.get('/api/v1/client', {params: {client_id: this.application.client_id}})
          .then((response) => {
            if (response.data.data)
              this.application = _.assign(this.application, response.data.data)
            else
              this.super_app = true

            if (this.application.allow_login_by.length == 1 && !this.method.qrCode && this.application.allow_login_by.includes("qr"))
              this.changeMethodLogin(this.application.allow_login_by.includes("qr"))

            if (this.application.allow_login_by.length == 1 && this.method.qrCode && this.application.allow_login_by.includes("password"))
              this.changeMethodLogin(false)
          })
          .catch((error) => {
            console.log(error);
          })
    },
    removeSession() {
      localStorage.removeItem("privyId")
      this.form.privyId = ''
      this.form.secret = ''
      this.isExist = false
      this.clearErrors()
    },
    clearErrors() {
      this.errors = ''
      document.getElementById('x-flash-errors').value = '[]'
    },
    setWithExpiry(key, value, ttl) {
      const now = new Date()

      // `item` is an object which contains the original value
      // as well as the time when it's supposed to expire
      const item = {
        value: value,
        expiry: now.getTime() + ttl,
      }
      localStorage.setItem(key, JSON.stringify(item))
    },
    getWithExpiry(key) {
      const itemStr = localStorage.getItem(key)
      // if the item doesn't exist, return null
      if (!itemStr) {
        return null
      }
      const item = JSON.parse(itemStr)
      const now = new Date()
      // compare the expiry time of the item with the current time
      if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
      }
      return item.value
    },
    changeMethodLogin(value) {
      const url = new URL(window.location.href)

      if (value) {
        url.searchParams.append('method', 'qr')
      } else {
        url.searchParams.delete('method')
      }

      window.location.href = `/login${url.search}`
    }

  }
}
</script>

